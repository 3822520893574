import { ApolloClient, InMemoryCache } from '@apollo/client'
// import { createUploadLink } from 'apollo-upload-client'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'

const client = new ApolloClient({
  uri: process.env.NEXT_PUBLIC_REACT_APP_GRAPHQL_ENDPOINT,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          // https://github.com/apollographql/apollo-client/blob/cbcf951256b22553bdb065dfa0d32c0a4ca804d3/src/utilities/policies/pagination.ts#L28
          // allBusinesses: offsetLimitPagination(),
          allBusinesses: {
            keyArgs: false,
            // read(existing, { args }) {
            //     // A read function should always return undefined if existing is
            //     // undefined. Returning undefined signals that the field is
            //     // missing from the cache, which instructs Apollo Client to
            //     // fetch its value from your GraphQL server.
            //     const { page = 0, perPage = 10 } = args;
            //     const offset = page * perPage;
            //     console.log(args)
            //     return existing && existing.slice(offset, offset + perPage);
            // },
            merge(existing, incoming, { args }) {
              return incoming
            },
          },
          _allBusinessesMeta: {
            keyArgs: false,
            merge(existing, incoming, { args }) {
              return incoming
            },
          },
        },
      },
    },
    addTypename: false,
  }),
  link: createUploadLink({ uri: process.env.NEXT_PUBLIC_REACT_APP_GRAPHQL_ENDPOINT }),
  // defaultOptions: {
  //   watchQuery: {
  //     fetchPolicy: 'no-cache',
  //     errorPolicy: 'ignore',
  //   },
  //   query: {
  //     fetchPolicy: 'no-cache',
  //     errorPolicy: 'all',
  //   },
  // }
})

export default client
