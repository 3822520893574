import React from 'react'
import Head from 'next/head'
import { SessionProvider } from 'next-auth/react'

import { ApolloProvider } from '@apollo/client'

import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

import client from '../config/apollo-client'
import '../styles/globals.css'
import theme from '../styles/theme'

export default function App({ Component, pageProps }) {
  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])

  return (
    <>
      <Head>
        <title>BeAKid editors tool</title>
        <meta name="description" content="Beakid editors portal" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SessionProvider session={pageProps.session}>
            <ApolloProvider client={client}>
              <CssBaseline />
              <Component {...pageProps} />
            </ApolloProvider>
          </SessionProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </>
  )
}
